.c-header {
  background-color: c('dark-blue');
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 0px 30px;
  &-nav{
    display: flex;
    align-items: center;
    z-index: 7;
    &--option{
      color: c('white');
      z-index: 7;
      display: block;
      position: relative;
    }
  }
  &--icon{
    display: none;
  }
}

.c-header-mobileWrapper {
  display: none;
}

.c-servicesOptions {
  width: 200px;
  height: 500px;
  z-index: 5;
  background-color: c('dark-blue');
  position: absolute;
}

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.dropdown-materials {
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.c-menu-op{
  background-color: c('dark-blue');
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 80px;
  font-size: 16px;
  color: c('white');
  height: 70px;
}
.c-menu-op:hover{
  background-color: c('medium-blue');
  color: c('white');
  text-decoration: none;
}