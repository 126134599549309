.c-table{
    border-collapse: collapse;
    width: 90%;
    margin: auto;
    margin-top: 2em;
    font-size: 11px;
    &--title{
        text-align: center !important;
    }
    th{
        border: 1px solid c('black');
        padding: 8px;
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: c('table-header');
        color: white;
    }
    td {
        border: 1px solid c('black');
        padding: 8px;
        width: 20%;
        &.extrawidth-col{
            width: 40%;
        }

        .table-align-center {
            display: flex;
            align-items: flex-start;
            gap: 8px;
        }
    }
    tr{
        background-color: c('table-op1');
    }
    tr:nth-child(even){
        background-color: c('table-op2');
    }
    tr:hover {
        background-color: c('light-gray');
    }
}

.c-seal{
    height: 80px;
    margin-right: 5%;
}

.c-fullscreen-img--box{
    position: relative;
    .c-fullscreen-img--desc{
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        padding: 20px;
        padding-bottom: 30px;
        transform: translate(-50%, -50%);
        background-color: #e6e7e8bd;
        width: fit-content;
    }
}

.c-fullscreen-img{
    width: 100%;
    height: calc(100vh - 100px - 25px - 55px);
    object-fit: cover;
    object-position: 0px 100%;
    &--no-subtitle{
        width: 100%;
        height: calc(100vh - 100px - 25px - 38px);
        object-fit: cover;
        object-position: 50% 100%;
        &--tradetherm{
            object-position: 50% 40%;
        }
        &--materik{
            object-position: 50% 80%;
        }
    }
}

.c-materials{
    background-color: c('light-blue');
    z-index: 3;
    padding: 30px;
    display: flex;
    flex-direction: column;
    h1{
        font-size: 36px;
        font-weight: 800;
    }
    &--product-list{
        width: 100%;
    }
    &--extra-img{
        display: none;
    }

    &--product-img{
        width: 100%;
        object-fit: cover;
        height: 497px;
    }
    &--product-img--box{
        width: 100%;
        margin-top: 20px;
        height: 497px;
        cursor: pointer;
        position: relative;
        .c-materials--showmore{
            background-image: linear-gradient(transparent, c('infinity'));
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 60px;
            display: flex;
            align-items: center;
            span{
                margin-top: 10px;
                color: white;
                margin-left: 30px;
                display: flex;
                align-items: center;
                img{
                    margin-right: 10px;
                }
            }
        }
    }
    &--product-img--box:hover{
        .c-materials--showmore{
            span{
                text-decoration: underline;
            }
        }
    }
    &--product-box{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &--wrapper{
            align-items: flex-start;
            margin: auto 0px;
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }
    &--guide{
        height: 50px;
        width: 200px;
        margin-top: 20px;
        background-color: c('boom-blue');
        color: c('white');
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 10px;
    }
    &--guide:hover{
        background-color: c('dark-blue');
    }
    &--header {
        display: flex;
        align-items: center;
        flex-direction: column;
        &--title{
            font-size: 32px;
            margin-block-end: 0;
            margin-block-start: 0;
            padding: 0px 10px;
            text-align: center;
            font-family: 'Avenir LT Std 95 Black';
        }
        &--subtitle {
            font-size: 14px;
            font-family: 'Avenir LT Std 95 Black';
        }
        &--icons{
            display: flex;
            width: 100%;
            margin-top: 20px;
            &--double{
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                margin-top: 30px;
                margin-left: 10px;
                .c-seal{
                    margin-left: 0%;

                }
            }
        }

    }
    &--properties{
        display: flex;
        flex-direction: row;
        align-items: center;
        img{
            margin-bottom: 5px;
            margin-right: 5px;
        }
    }
    &--prop{
        &-box{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 30px;
        }
        &-col{
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    }
    &--presentation {
        font-size: 18px;
        font-family: 'Avenir LT Std 85 Heavy';
        text-align: center;
    }
    &--subpresentation {
        font-size: 15px;
        font-style: italic;
        font-weight: 300;
        margin-bottom: 20px;
        font-family: 'Avenir LT Std 35 Light';
    }
    &--base {
        font-size: 17px;
        font-family: 'Avenir LT Std 85 Heavy';
    }
    &--exp {
        font-size: 16px;
        font-family: 'Avenir LT Std 35 Light';
        line-height: 22px;
        padding-left: 23px;
    }

    &--sis {
        font-size: 20px;
        font-family: 'Avenir LT Std 85 Heavy';
        text-align: center;
        background-color: c('light-blue');
    }
    &--sis-box{
        background-color: c('light-blue');
        z-index: 2;
        padding: 0 20px;
        margin-top: 20px;
    }
    &--hr{
        border-top: 1px solid c('rustop');
        height: 1px;
        width: 100%;
        margin-top: 28px;
        position: absolute;
    }
    &--hr-title{
        display: flex;
        position: relative;
        justify-content: center;
        margin: 15px 0px;
        text-align: center;
    }
    &--notes-box{
        background-color: c('light-gray');
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        padding-bottom: 10px;
        width: 70%;
        margin-left: 15%;
    }
    &--note {
        font-size: 15px;
        font-style: bold;
        font-weight: 500;
        line-height: 15px;
        margin-bottom: 10px;
        margin-left: 30px;
        margin-right: 15px;
        &__title{
            margin-top: 10px;
            margin-bottom: 5px;
            margin-left: 15px;
            font-size: 16px;
            font-family: 'Avenir LT Std 85 Heavy';
            font-weight: 500;
            line-height: 30px;
        }
    }
    &--top{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px 0px 25px;
        padding-top: 100px;
    }
    &--block{
        display: flex;
        flex-direction: column;
        font-size: 16px;
        padding: 20px 20px;
        border: 1px solid c('dark-blue');
        &--op{
            display: flex;
            align-items: center;
            height: 40px;
            font-family: 'Avenir LT Std 85 Heavy';
        }
        &--prod{
            display: flex;
            align-items: center;
            margin-left: 25px;
            cursor: pointer;
            color: c('black')
        }
        &--prod:hover{
            color: c('black')
        }
    }
    &--button{
        margin-top: 20px;
        padding: 15px 25px;
        display: flex;
        justify-content: space-between;
        background-color: c('dark-blue');
        color: c('white');
        font-weight: 800;
    }
    &--table{
        width: 100%;
        overflow-x: auto;
    }
}

@include medium {
    @import'medium';
}
@include large {
    @import'large';
}