.c-header {
  background-color: c('dark-blue');
  display: flex;
  align-items: center;
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  z-index: 12;
  justify-content: space-between;
  height: 70px;
  padding: 0px 30px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  &-nav{
    display: flex;
    align-items: center;
    width: 100%;
    &__options {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      width: 100%;
    }
    &--option{
      display: none;
    }
  }
  &--logo{
    height: 55px;
    display: flex;
    align-items: center;
    margin-right: 40px;
    img{
      height: 80%;
    }
  }
  &--icon{
    display: block;
    width: 35px;
  }
}

.c-header-mobile{
  background-color: c('dark-blue');
  position: fixed;
  width: 300px;
  height: 100%;
  right: 0;
  z-index: 15;
  display: flex;
  flex-direction: column;
  border-left: 1px solid c('light-blue');
  &--baseHeader{
    display: flex;
    justify-content: space-between;
    height: 60px;
    padding: 0px 20px;
    border-bottom: 1px solid c('light-blue');
  }
  &--back{
    display: flex;
    height: 60px;
    padding: 0px 20px;
    align-items: center;
    color: c('white');
    border-bottom: 1px solid c('light-blue');
    cursor: pointer;
  }
  &--icon{
    width: 22px;
    cursor: pointer;
    &__rotate{
      transform: rotate(270deg);
    }
    &__rotate2{
      transform: rotate(90deg);
      margin-right: 10px;
    }
  }
  &--option{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    height: 60px;
    color: c('white');
    border-bottom: 1px solid c('light-blue');
    cursor: pointer;
  }
  &--optionTitle{
    padding: 0px 20px;
    height: 60px;
    color: c('white');
    background-color: c('boom-blue');
    border-bottom: 1px solid c('light-blue');
    font-weight: 800;
    display: flex;
    align-items: center;
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
  &--option:hover{
    color: c('bestial-blue');
  }
}
.c-header-mobileWrapper{
  background-color: rgba(0,0,0,0.2);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 13;
}

a:link {
  line-height: 24px;
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  color: c('rustop');
}

a:active {
  color: c('bestial-blue');
}

.centered{
  justify-content: center;
}

/*
* DROPDOWN
*/

.c-dropmat{
  &--box{
    width: 14.5%;
    display: flex;
    overflow: hidden;
    height: 150px;
    position: relative;
    flex-direction: column;
    margin: 20px 0px 30px;
    overflow: hidden;
    cursor: pointer;
    span {
      color: c('white');
      position: absolute;
      bottom: 0px;
      height: 40px;
      display: flex;
      align-items: center;
      font-family: 'Avenir LT Std 95 Black';
      text-shadow: 0 0 0.2em #000;
      justify-content: center;
      background-color: rgba(40,55,86,0.5);
      width: 100%;
      transition: 0.5s;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.5s, filter 1s ease-in-out;
      filter: brightness(80%);
      transform: scale(1.2);
    }

    &.dropmat-materik {
      object-position: 0px 0px;
    }
  }
  &--box:hover{
     img {
      filter: brightness(100%);
      transform: scale(1);
     }
     span {
        background-color: c('rustop');
     }
  }
}

.dropbtn {
  background-color: c('dark-blue');
  color: white;
  padding: 0px 80px;
  height: 70px;
  font-size: 16px;
  border: none;
  display: flex;
  align-items: center;
}

.dropdown {
  position: relative;
  display: none;
}

.c-menu-op{
  display: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: c('dark-blue');
  min-width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-materials {
  display: none;
}
.dropdown-content-materials {
  left: 0px;
  display: none;
  position: absolute;
  justify-content: space-around;
  background-color: c('dark-blue');
  gap: 6px;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 15px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.dropdown-materials:hover .dropdown-content-materials {display: flex;}
.dropdown-materials:hover .dropbtn {background-color: c('medium-blue');}


.dropdown-content a {
  color: c('white');
  padding: 18px 25px;
  text-decoration: none;
  display: flex;
  justify-content: center;
}

.dropdown-content a:hover {background-color: c('rustop');}

.dropdown:hover .dropdown-content {display: block;}



.dropdown:hover .dropbtn {background-color: c('medium-blue');}

/*
 *  SCROLLBAR
 */

 ::-webkit-scrollbar-track
 {
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
   box-shadow: inset 0 0 6px rgba(0,0,0,0.1);;
   background-color: c('celestial-blue');
   border-radius: 10px;
 }
 
 ::-webkit-scrollbar
 {
   width: 10px;
   background-color: c('celestial-blue');
 }
 
 ::-webkit-scrollbar-thumb
 {
   border-radius: 10px;
   background-color: c('celestial-blue');
   background-image: linear-gradient(to top, c('dark-blue'), c('bestial-blue'), c('dark-blue'));
 }

.opacity-off{
  opacity: 0;
  display: none !important;
}

 .fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;} 
}
@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;} 
}


@include medium {
    @import'medium';
}
@include large {
    @import'large';
}