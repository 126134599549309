.c-contact{
    background-color: c('light-blue');
    z-index: 3;
    padding: 60px;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    &--title{
        font-size: 32px;
        font-family: 'Avenir LT Std 95 Black';
        text-align: center;
        margin-block-end: 20px;
        margin-block-start: 0;
    }
    &--map{
        border: 1px solid c('black');
        margin-bottom: 20px;
        margin-right: 70px;
    }
    &--container{
        display: flex;
        margin-top: 7px;
        justify-content: center;
        flex-wrap: wrap;
    }
    button {
        height: 30px;
        width: 100px;
        margin: auto;
        margin-top: 0px;
    }
}

.c-social-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    img{
        width: 200px;
    }
    span{
        color: c('dark-blue');
        width: 100px;
    }
}
.c-social-container:hover span{
    font-family: 'Avenir LT Std 85 Heavy';
}