.c-company{
    background-color: c('light-blue');
    z-index: 3;
    padding: 30px;
    display: flex;
    padding-top: 100px;
    flex-direction: column;
    h1{
        font-size: 32px;
        font-family: 'Avenir LT Std 95 Black';
        text-align: center;
        margin-block-end: 20px;
        margin-block-start: 0;
    }
    h4{
        margin-top: 20px;
        font-family: 'Avenir LT Std 85 Heavy';
        line-height: 27px;
        margin-bottom: 10px;
    }
    p {
        line-height: 25px;
        margin-block-start: 0em;
        margin-block-end: 1em;
    }
    &--border{
        width: 40px;
        border-top: 1px solid black;
        height: 1px;
        margin-bottom: 15px;
    }
    &--top{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        align-items: center;
        flex-wrap: wrap;
    }
    &--filo{
        margin-block-end: 15px;
        font-family: 'Avenir LT Std 85 Heavy';
    }
    &--left-top{
        width: 100%;
        margin-left: 20px;
        text-align: justify;

    }
    &--right-top{
        width: 100%;
        overflow: hidden;
    }
    &--carousels{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &--carousel{
        &-box{
            width: 49%;
            margin-top: 20px;
            display: none;
            &--left{
                width: 50%;
            }
            &--mobile{
                margin-top: 20px;
                width: 100%;
                display: flex;
            }
        }
        &-right{
            height: 300px;
        }
        &-left{
            height: 300px;
        }
        .item{
            height: 300px;
            overflow: hidden;
            img{
                height: 300px;
                object-fit: cover;
                object-position: 50% 40%;
            }
            
        }
    }
    &--img{
        width: 100%;
        object-fit: contain;
        object-position: 50% 0px;
    }
    &--card{
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        justify-content: space-between;
        flex-direction: column-reverse;
        align-items: center;
        margin-bottom: 50px;
        &--left{
            width: 100%;
            position: relative;
        }
        &--right{
            width: 100%;
            text-align: justify;
            h4{
                margin-block-start: 1em;
            }
        }
        &--img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        &--end{
            margin-block-start: 2em !important;
        }
        &--firm{
            text-align: right;
        }
    }

    &--profile{
        margin: auto;
        padding: 20px;
        border: none;
        border-radius: 10px;
        background-color: c('medium-blue');
        transition-duration: 0.4s;
        color: c('white');
        font-size: 16px;
        font-family: 'Avenir LT Std 85 Heavy';
    }
    &--profile:hover{
        background-color: c('boom-blue');
        color: c('white');
        text-decoration: none;
    }
}

@include medium {
    @import'medium';
}
@include large {
    @import'large';
}