.c-footer-top{
    &--nav{
        &__col{
            width: 50%;
            text-align: center;
        }
        &__colSpecial{
            width: 100%;
            text-align: center;
        }
    }
    &--social{
        justify-content: flex-start;
        margin-left: -90px;
    }
}