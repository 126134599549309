.c-showrooms{
    &--left{
        width: 43%;
        display: flex;
        flex-direction: column;
        img{
            width: 70%;
            margin: 10px auto 0px;
        }
    }
    &--right{
        width: 54%;
        padding-bottom: 0px;
        justify-content: center;
        gap: 10px
    }
    &--img{
        width: 22%;
        margin-top: 0px;
        margin-left: 0px;
    }
    &--content{
        margin-top: 20px;
    }
}