.c-courses{
    background-color: c('light-blue');
    z-index: 3;
    padding: 30px;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    &--carousel-wrapper{
        width: 100%;
    }
    &--info{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        margin-top: 30px;
    }
    &--video-link{
        background-color: black;
        color: c('white');
        padding: 10px 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        span{
            margin-top: 3px;
            margin-left: 15px;
        }
    }
    &--text{
        width: 100%;
    }
    &--carousel{
        height: 400px;
        .owl-item{
            background-color: c('rustop');
            height: 400px;
            .item{
                height: 400px;
                img{
                object-fit: cover;
                height: 100%;
                }
            }
        }
    }
    h1{
        font-size: 32px;
        font-family: 'Avenir LT Std 95 Black';
        text-align: center;
        margin-block-end: 0px;
        margin-block-start: 0;
    }
    p{
        line-height: 22px;
    }
    a{
        color: c('dark-blue');
    }
    a:hover{
        color: c('boom-blue');
    }
}

@include medium {
    @import'medium';
}
@include large {
    @import'large';
}