.c-language{
    width: 120px;
    height: 50px;
    color: c('white');
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    cursor: pointer;
    &--flag, &--lang {
        margin-right: 5px;
    }
    &-options{
        background-color: c('dark-blue');
        border-top: none;
        position: absolute;
        display: flex;
        flex-direction: column;
        margin-left: 18px;
        z-index: 10;
    }
    &-option{
        background-color: c('dark-blue');
        border: none;
        transition-duration: 0.4s;
        cursor: pointer;
        color: c('white');
        padding: 17px 50px;
        justify-content: center;
        display: flex;
        align-items: center;
    }
    &-option:hover {
        background-color: c('boom-blue');
    }
    &--icon{
        &__opened{
            -webkit-animation: spin 0.2s ease-in-out;
            -moz-animation: spin 0.2s linear;
            animation: spin 0.2s linear;
            animation-fill-mode: forwards; 
        }
    }
}

.language{
    position: relative;
}

@-moz-keyframes spin {
    100% { -webkit-transform: rotate(180deg); transform:rotate(180deg); }
}
@-webkit-keyframes spin {
    100% { -webkit-transform: rotate(180deg); transform:rotate(180deg); }
}
@keyframes spin { 
    100% { -webkit-transform: rotate(180deg); transform:rotate(180deg); } 
}