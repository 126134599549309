.c-footer-top{
    &--nav{
        &__col{
            width: 17%;
            text-align: left;
        }
        &__colSpecial{
            margin-right: 20px;
            margin-block-end: 0px;
            margin-bottom: 0px;
            align-items: flex-start;
        }
    }
    &--social{
        margin-left: -25%;
    }
}