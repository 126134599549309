.c-footer {
    width: 100%;
    &-bottom {
        background-color: c('black');
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0px 30px;
        color: c('white');
        justify-content: center;
        font-size: 12px;
    }
    &-top{
        background-color: c('infinity');
        display: flex;
        align-items: center;
        padding: 25px 30px 0px;
        color: c('white');
        &--social{
            margin-top: 36px;
            height: auto;
            width: 100%;
            color: c('white');
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            &-img{
                width: 110px;
            }
        }
        &--social:hover{
            color: c('white');
            font-family: 'Avenir LT Std 85 Heavy';
        }
        &--nav {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            justify-content: space-around;
            padding: 0 10px;
            box-sizing: border-box;
            &__col{
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: center;
                margin-bottom: 13px;
                text-align: center;
                .traderfill-logo {
                    width: 90px;
                    margin-top: 8px
                }
            }
            &__colSpecial{
                margin-right: 0px;
                margin-block-start: 0px;
                margin-block-end: 30px;
            }
        }
        &--company{
            color: c('white');
            font-size: 18px;
            font-family: 'Avenir LT Std 95 Black';
        }
        &--email{
            color: c('white');
            font-family: 'Avenir LT Std 85 Heavy';
        }
        &--option {
            color: c('white');
            line-height: 24px;
            cursor: pointer;
            &__top{
            font-family: 'Avenir LT Std 85 Heavy';
            font-size: 18px;
            color: c('white');
            padding-bottom: 11px;
            }
        }
        &--option:hover{
            text-decoration: underline;
            color: c('rustop');
        }
        p{
            line-height: 24px;
        }
    }
  }

  
  
  @include medium {
      @import'medium';
  }
  @include large {
      @import'large';
  }