.c-form{
    display: flex;
    flex-direction: column;
    width: 500px;
    &--checkbox{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        label{
            margin-left: 15px;
            margin-top: 4px;
        }
    }
    &--error{
        border: 2px solid c('error');
    }
    &--privacy{
        cursor: pointer;
    }
    &--privacy:hover{
        cursor: pointer;
        text-decoration: underline;
        color: c('rustop');
    }
}

textarea{
    font-family: Arial, Helvetica, sans-serif;
    background-color: c('light-gray');
    border: none;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 5px;
}
input{
    background-color: c('light-gray');
    border-radius: 5px;
    border: none;
    margin: 10px 0px;
    height: 32px;
    padding: 0 10px;
}