.c-table{
    font-size: 14px;
}
.c-materials{
    padding: 60px;
    &--prop{
        &-col{
            width: 49%;
        }
    }
}