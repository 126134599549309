.c-arrow {
    background-color: c('dark-blue');
    border: 1px solid c('rustop');
    border-radius: 15%;
    position: fixed;
    bottom: 35px;
    right: 40px;
    width: 30px;
    height: 30px;
    transform: rotate(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
}