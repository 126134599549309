.c-materials{
    &--product-list{
        width: 100%;
    }
    &--product-img{
        width: 100%;
    }
    &--product-box{
        &--wrapper{
            width: 49%;
        }
    }
    &--extra-img{
        width: 100%;
        object-fit: cover;
        object-position: 50%;
        margin-top: 20px;
        display: block;
        &--tradegraff{
            height: 117px;
            object-position: 50% 40%;
        }
        &--rustop{
            height: 342px;
        }
        &--hydrograff{
            height: 140px;
        }
        &--tradecote{
            height: 142px;
        }
        &--tradetherm{
            height: 342px;
        }
    }
    &--product-img--box{
        width: 49%;
        margin-top: 20px;
        height: 497px;
        cursor: pointer;
        position: relative;
        .c-materials--showmore{
            background-image: linear-gradient(transparent, c('infinity'));
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 60px;
            display: flex;
            opacity: 0.6;
            align-items: center;
            span{
                margin-top: 10px;
                color: white;
                margin-left: 30px;
                display: flex;
                align-items: center;
                img{
                    margin-right: 10px;
                }
            }
        }
    }
    &--product-img--box:hover{
        .c-materials--showmore{
            opacity: 1;
        }
    }
}
.c-table{
    font-size: 16px;
}