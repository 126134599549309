$colors: (
	"dark-blue": #191261,
	"medium-blue": #3b3a4b,
	"light-blue": #FFF,
	"light-gray": #e6e7e8,
	"white": white,
	"black": black,
	"bestial-blue": #8bb4c1,
	"celestial-blue": #a3c1e5,
	"boom-blue": #292183,
	"table-header": #6c83ae,
	"table-op1": #adb8ce,
	"table-op2": #c1cde3,
	"error": #b50000,

	"infinity": #191261,
	"rustop": #6D6E70,
	"tradegraff": #770000,
	"tradecote": #03572C,
	"hydrograff": #03572C,
	"tradetherm": #000000,
);