@font-face {
  font-family: 'Avenir LT Std 65 Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir LT Std 65 Medium'), url('./avenir-lt-std-cufonfonts-webfont/AvenirLTStd-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Avenir LT Std 35 Light';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir LT Std 35 Light'), url('./avenir-lt-std-cufonfonts-webfont/AvenirLTStd-Light.woff') format('woff');
  }

@font-face {
  font-family: 'Avenir LT Std 85 Heavy';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir LT Std 85 Heavy'), url('./avenir-lt-std-cufonfonts-webfont/AvenirLTStd-Heavy.woff') format('woff');
}

@font-face {
  font-family: 'Avenir LT Std 95 Black';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir LT Std 95 Black'), url('./avenir-lt-std-cufonfonts-webfont/AvenirLTStd-Black.woff') format('woff');
}

body {
  margin: 0;
  font-family: 'Avenir LT Std 65 Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  white-space: pre-line;
}