.c-home{
    height: 100vh;
    background-color: c('dark-blue');
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    img{
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        width: 300px;
        z-index: 1;
    }
    .fadeOut {
        -webkit-animation-name: fadeOut;
        animation-name: fadeOut;
    }
    .bounceIn {
        -webkit-animation-name: bounceIn; 
        animation-name: bounceIn;
    }
    &--presentation {
        position: absolute;
        color: white;
        text-align: center;
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
        opacity: 0;
        margin-top: 35px;
        h1{
            font-size: 30px;
            font-family: 'Avenir LT Std 95 Black';
            margin-block-start: 0em;
            margin-block-end: 0em;
            margin-bottom: 20px;
            padding: 20px;
            text-shadow: 0 0 0.2em #000
        }
        h2 {
            font-size: 20px;
            font-family: 'Avenir LT Std 65 Medium';
            text-shadow: 0 0 0.2em #000
        }
    }
    &--background-container{
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100vh; 
        overflow: hidden;
    }
}
.background-video{
    position: absolute;
    width: auto;
    height: auto;
    min-width: 100%; 
    min-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.overflowHidden{
    overflow: hidden;
}

.notDisplay {
    display: none;
}

.c-page-404 {
    height: calc(100vh - 279px);
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
        margin-block-start: 0;
        margin-block-end: 0;
    }
}

@-webkit-keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;} 
}
@keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;} 
}

@-webkit-keyframes bounceIn {
    0% {opacity: 0; transform: scale(.3);}
    100% {opacity: 1; transform: scale(1);} 
}

@keyframes bounceIn {
    0% {opacity: 0; transform: scale(.3);}
    100% {opacity: 1; transform: scale(1);} 
}

@include medium {
    @import'medium';
}
@include large {
    @import'large';
}