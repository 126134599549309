.c-showrooms{
    background-color: c('light-blue');
    z-index: 3;
    padding: 30px;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    h1{
        font-size: 32px;
        font-family: 'Avenir LT Std 95 Black';
        text-align: center;
        margin-block-end: 10px;
        margin-block-start: 5px;
    }
    p{
        line-height: 22px;
    }
    a{
        color: c('dark-blue');
    }
    a:hover{
        color: c('boom-blue');
    }
    &--left{
        width: 100%;
        img{
            width: 100%;
        }
    }
    &--right{
        display: flex;
        justify-content: space-around;
        width: 100%;
        padding-bottom: 15px;
        overflow: hidden;

    }
    &--content{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
    }
    &--text{
        text-align: justify;
    }
    &--img{
        height: 100%;
        width: 23.5%;
        margin-top: 20px;
        object-fit: cover;
        padding-bottom: 10px;
    }
    &--expositor{
        margin-top: 10px;
    }
}

@include medium {
    @import'medium';
}
@include large {
    @import'large';
}